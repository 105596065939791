import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getBackups (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/backup${queries}`,
    method: 'get'
  })
}

export function insertBackup () {
  return axios({
    url: 'v1/admin/backup',
    method: 'post'
  })
}

export function restoreBackup (id) {
  return axios({
    url: `v1/admin/backup/${id}/restore`,
    method: 'put'
  })
}
